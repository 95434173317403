export const costumes : { [key: string]: { [key: string]: string } } = {
  Cap: {
    Mario             : 'Mario Cap',
    MarioCaptain      : 'Captain\'s Hat',
    MarioTailCoat     : 'Black Top Hat',
    MarioPrimitiveMan : 'Caveman Headwear',
    MarioPoncho       : 'Sombrero',
    MarioGunman       : 'Cowboy Hat',
    MarioSwimwear     : 'Swim Goggles',
    MarioExplorer     : 'Explorer Hat',
    MarioScientist    : 'Scientist Visor',
    MarioPilot        : 'Aviator Cap',
    MarioMaker        : 'Builder Helmet',
    MarioGolf         : 'Golf Cap',
    MarioSnowSuit     : 'Snow Hood',
    MarioAloha        : 'Resort Hat',
    MarioSailor       : 'Sailor Hat',
    MarioCook         : 'Chef Hat',
    MarioPainter      : 'Painter\'s Cap',
    MarioArmor        : 'Samurai Helmet',
    MarioHappi        : 'Happi Headband',
    MarioSpaceSuit    : 'Space Helmet',
    Mario64           : 'Mario 64 Cap',
    MarioShopman      : 'Employee Cap',
    MarioNew3DS       : 'Fashionable Cap',
    MarioMechanic     : 'Mechanic Cap',
    MarioSuit         : 'Black Fedora',
    MarioPirate       : 'Pirate Hat',
    MarioClown        : 'Clown Hat',
    MarioFootball     : 'Football Helmet',
    MarioColorClassic : 'Classic Cap',
    MarioColorLuigi   : 'Luigi Cap',
    MarioDoctor       : 'Doctor Headwear',
    MarioColorWaluigi : 'Waluigi Cap',
    MarioDiddyKong    : 'Diddy Kong Hat',
    MarioColorWario   : 'Wario Cap',
    MarioKoopa        : 'Bowser\'s Top Hat',
    MarioPeach        : 'Bridal Veil',
    MarioTuxedo       : 'Mario\'s Top Hat',
    MarioColorGold    : 'Gold Mario Cap',
    Mario64Metal      : 'Metal Mario Cap',
    MarioKing         : 'King\'s Crown',
    MarioInvisible    : 'Invisibility Hat',
  },
  Body: {
    Mario             : 'Mario Suit',
    MarioTailCoat     : 'Black Tuxedo',
    MarioPrimitiveMan : 'Caveman Outfit',
    MarioPoncho       : 'Poncho',
    MarioGunman       : 'Cowboy Outfit',
    MarioSwimwear     : 'Swimwear',
    MarioExplorer     : 'Explorer Outfit',
    MarioScientist    : 'Scientist Outfit',
    MarioPilot        : 'Aviator Outfit',
    MarioMaker        : 'Builder Outfit',
    MarioGolf         : 'Golf Outfit',
    MarioSnowSuit     : 'Snow Suit',
    MarioAloha        : 'Resort Outfit',
    MarioSailor       : 'Sailor Suit',
    MarioCook         : 'Chef Suit',
    MarioPainter      : 'Painter Outfit',
    MarioArmor        : 'Samurai Armor',
    MarioHappi        : 'Happi Outfit',
    MarioSpaceSuit    : 'Space Suit',
    Mario64           : 'Mario 64 Suit',
    MarioShopman      : 'Employee Uniform',
    MarioUnderwear    : 'Boxer Shorts',
    MarioNew3DS       : 'Fashionable Outfit',
    MarioMechanic     : 'Mechanic Outfit',
    MarioSuit         : 'Black Suit',
    MarioPirate       : 'Pirate Outfit',
    MarioClown        : 'Clown Suit',
    MarioFootball     : 'Football Uniform',
    MarioColorClassic : 'Classic Suit',
    MarioBone         : 'Skeleton Suit',
    MarioColorLuigi   : 'Luigi Suit',
    MarioDoctor       : 'Doctor Outfit',
    MarioColorWaluigi : 'Waluigi Suit',
    MarioDiddyKong    : 'Diddy Kong Suit',
    MarioColorWario   : 'Wario Suit',
    MarioHakama       : 'Hakama',
    MarioKoopa        : 'Bowser\'s Tuxedo',
    MarioPeach        : 'Bridal Gown',
    MarioTuxedo       : 'Mario\'s Tuxedo',
    MarioColorGold    : 'Gold Mario Suit',
    Mario64Metal      : 'Metal Mario Suit',
    MarioKing         : 'King\'s Outfit',
  },
}
